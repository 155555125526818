<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col lg="2" md="2">
      <!--Clear selection-->
      <v-btn v-if="selection.length > 0" @click="$emit('clearSelection')"
        ><v-icon small left>mdi-refresh</v-icon>Clear All</v-btn
      >
    </v-col>
    <v-col lg="6" md="6">
      <v-row align="center">
        <v-col class="grow">
          <v-text-field
            v-model="search"
            label="Search..."
            append-icon="mdi-magnify"
            dense
            outlined
            @input="$emit('passSearch', search)"
            color="mt-3"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "CustomerPaymentsFilters",
  props: ["selection"],
  data() {
    return {
      search: "",
    };
  },
};
</script>